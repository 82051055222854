// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import $ from 'jquery';

import 'select2';
import 'glDatePicker/glDatePicker.min.js';

import 'foundation/foundation';
import 'foundation/foundation.reveal';

import myEwinSettings from 'markitup/sets/default/set.js';
import 'markitup/jquery.markitup.js';

import 'anytime'
import 'anytimetz'
import 'highcharts';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('select2:open', (e) => {
  document.querySelector('.select2-search__field').focus();
});

document.addEventListener("turbolinks:load", () => {

  $.fn.AnyTime_picker = window.$.fn.AnyTime_picker;
  $.fn.glDatePicker = window.$.fn.glDatePicker;
  $.fn.foundation = window.$.fn.foundation;
  $.fn.markItUp = window.$.fn.markItUp;

  $(".datetimepicker-clear").click(function(){
    $(".datetimepicker").val("").change();
  });

  $(".datetimepicker").AnyTime_picker(
    { //warning, This is not the strftime you are looking for,see http://www.ama3.com/anytime/
      format: "%e %b. %Y, %l:%i %p",
      formatUtcOffset: "%: (%@)",
      hideInput: false,
      labelHour: false,
      askSecond: false
      //placement: "inline"
    }
  );

  $(document).foundation();

  $('.select2').select2();
  var recipient_search = $('#recipient-search');
  if (recipient_search.length) {
    var email_id = recipient_search.data('email-id');
    var recipients_url = "/recipients/autocomplete.json?email=" + email_id;
    recipient_search.select2({
      minimumInputLength: 3,
      width: 400,
      placeholder: 'Search',
      ajax: {
        url: recipients_url,
        dataType: 'json',
        results: function (data, page) {
          return { results: data };
        }
      },
      templateResult: function(data) {
        if (!data.id) {
          return data.text;
        }
        return $('<span>' + data.text + "<br/>" + data.id + '</span>');
      }//,
      //formatSelection: function(data) {
        //return data.text + " | " + data.id;
      //}
    });
  }

  $('form').submit(function(){
    $('input[type=submit]', this).attr('disabled', 'disabled');
  });

  $('.capitalize_as_you_type').keyup(function(){
    var new_string = $(this).val().toUpperCase();
    $(this).val(new_string);
  });

  $('#email_html_body').markItUp(myEwinSettings);

  // Links between html and text body
  $('#text_body_link').click(function(e){
    e.preventDefault();
    // $('#redirect_url').removeAttr('disabled');
    // $('#save_button').click();
    // Delay the redirect so the form can remotly submit
    setTimeout((window.location.href = $('#text_body_link').attr('href')),800);
  });

  $('#html_body_link').click(function(e){
    e.preventDefault();
    // $('#redirect_url').removeAttr('disabled');
    // $('#save_button').click();

    // Delay the redirect so the form can remotly submit
    setTimeout((window.location.href = $('#html_body_link').attr('href')),800);
  });

  // Quick Preview
  $('#quick_preview').click(function(){
    setTimeout(load_preview_modal,800);
  });

  // Remote form submit
  $('#email_body_form input, #email_body_form textarea').blur(function(){
    // $('#xhr_field').removeAttr('disabled');
    // $('#email_body_form').submit();
    $.post(this.form.action, $(this.form).serialize());
    $('#email_saved_indicator').fadeIn(200).delay(200).fadeOut('slow');
  });

  // Check Spam Score
  $('#check_spam_score').click(function(){
    var url = $('#check_spam_score_url').text();
    $.get(url, function(response){
      $('#spam_check_response').html(response);
      $('#spam_check_modal').foundation('reveal', 'open');
    });
  });

  // export excel on jobs_and_emails page
  $('form#emails-with-jobs a.export').click(function(){
    $('<input>').attr({ type: 'hidden', name: 'type', class: 'temp' }).val('export').appendTo('form#emails-with-jobs');
    $('form#emails-with-jobs').submit();
    $('form#emails-with-jobs input.temp').remove();
    return false;
  });

  // set options for future dates
  var now = new Date();
  var twentyYearsFromNow = new Date();
  twentyYearsFromNow.setYear(now.getFullYear() + 20);
  var datepickerOptions = {
    prevArrow: "\u2190",
    nextArrow: "\u2192",
    selectableDateRange: [
      {
        from: now,
        to: twentyYearsFromNow
      },
    ],
    onClick: function(target, cell, date, data) {
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      var day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      target.val(date.getFullYear() + '-' + month + '-' + day);
    }
  };
  $('.datepicker').glDatePicker(datepickerOptions);

  // set options for past dates
  var tenYearAgo = new Date()
  tenYearAgo.setYear(now.getFullYear() - 10);
  datepickerOptions.selectableDateRange = [{
    from: tenYearAgo,
    to: now
  }];

  $('.datepicker-past').glDatePicker(datepickerOptions);

  $("#select_all").on("change", function(){
    $(":checkbox").prop('checked', this.checked);
  });
});


function load_preview_modal() {
  // path = $('#iframe_url').text();
  // $('#quick_preview_modal').load(path);
  // $('#quick_preview_modal').foundation('reveal', 'open');
  $('#quick_preview_modal').foundation('reveal', 'open');
}
